const getQueryParam = function(strToSearchFor) {
  let searchUrl = window.location.search;
  try {
    searchUrl = decodeURI(searchUrl);
  } catch (e) {
    console.log('getQueryParam decode error', e);
  }
  const questionStr = '?' + strToSearchFor + '=';
  const ampStr = '&' + strToSearchFor + '=';
  if (searchUrl.length) {
    let startIndex = searchUrl.indexOf(questionStr);
    if (startIndex < 0) startIndex = searchUrl.indexOf(ampStr);
    if (startIndex >= 0) {
      const endIndex = searchUrl.indexOf('&', startIndex + 1);
      let result = '';
      if (endIndex >= 0) {
        result = searchUrl.slice(startIndex + questionStr.length, endIndex);
      } else {
        result = searchUrl.slice(startIndex + questionStr.length);
      }
      try {
        return decodeURIComponent(result);
      } catch (e) {
        return result;
      }
    }
  }
  return '';
};

export default {
  getQueryParam,
};

import { ApolloClient } from 'apollo-client';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
  defaultDataIdFromObject,
} from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { RetryLink } from 'apollo-link-retry';
import { ApolloLink } from 'apollo-link';
import envUtils from './env';
import introspectionQueryResultData from '../graphql/fragmentTypes.json';

const dataIdFromObject = object => {
  if (!object.__typename) return defaultDataIdFromObject(object);
  switch (object.__typename) {
    case 'UserWithAuth':
      return 'UserWithAuth:me';
    case 'Customer':
      return `Customer:${object.userId}`;
    case 'Expert':
      return `Expert:${object.userId}`;
    default:
      return defaultDataIdFromObject(object);
  }
};

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const cache = new InMemoryCache({ fragmentMatcher, dataIdFromObject });

const retryLink = new RetryLink();

const httpLink = new HttpLink({
  uri: envUtils.pick(
    'https://api.storetasker.com/graphql',
    'https://dev.storetasker.com/graphql',
    'http://localhost:4000/graphql',
  ),
});

const create = () => {
  const client = new ApolloClient({
    cache: cache.restore({
      'UserWithAuth:me': { __typename: 'UserWithAuth', token: '' },
    }),
    link: ApolloLink.from([retryLink, httpLink]),
    shouldBatch: true,
    connectToDevTools: envUtils.pick(false, true, true),
  });

  return client;
};

export default {
  create,
};

import AirbrakeClient from 'airbrake-js';
import envUtils from './env';
const airbrake = new AirbrakeClient({
  projectId: 220897,
  projectKey: 'd30a82d786583566134ec87f97d2f508',
});

/**
 * Ensure airbrake sends to proper environment. Add filters here if we don't want certain errors to reach Airbrake
 * @type {Notice} forwards notice to notify
 */
airbrake.addFilter(notice => {
  notice.context.environment = envUtils.get();
  if (shouldFilter(notice.errors)) return null;
  return envUtils.pick(notice, notice, null); // don't send errors in dev
});

const shouldFilter = errors => {
  if (errors.find(el => el.message.includes('Audio node triggered too many')))
    return true;
  if (errors.find(el => el.message.includes('GraphQL error'))) return true;
  if (
    errors.find(el =>
      el.message.includes(
        'A message was not sent because socket is not connected, is closing or is already closed',
      ),
    )
  )
    return true;
  return false;
};

/**
 * Error logging for the app.
 * @param  {String | Error}  err           Error object or String error message
 * @param  {Object}  params                Params like 'info', used for error bubbling in React ErrorBoundary component
 * @param  {String | undefined} severity   See https://airbrake.io/docs/airbrake-faq/what-is-severity/
 * @return {undefined}
 */
const logError = (err, params, severity) => {
  console.error(`logError(severity=${severity || 'error'})`, err, params);
  airbrake.notify({
    error: err,
    params: { ...params },
    context: { severity },
  });
};

export default logError;

import React, { Component, Fragment } from 'react';
import { debounce } from 'lodash';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import Star from 'react-ionicons/lib/MdStar';
import PropTypes from 'prop-types';
import logError from '../utils/airbrake';
import envUtils from '../utils/env';
import marketingUtils from '../utils/marketing';
import '../styles/PXUServices.scss';

const CREATE_PREFAB = gql`
  mutation prefabCreateBasic($title: String!, $descriptionPlainText: String!) {
    prefabCreateBasic(
      title: $title
      descriptionPlainText: $descriptionPlainText
    ) {
      id
    }
  }
`;

const APP_HOST = envUtils.pick(
  'https://www.storetasker.com',
  'https://www-dev.storetasker.com',
  'http://localhost:3000',
);
const partnerTrackingId = 'smart-theme';
const partnerPage = APP_HOST + '/partners/' + partnerTrackingId;
const partnerName = 'Smart';
class SmartServices extends Component {
  static propTypes = {
    mutate: PropTypes.func.isRequired,
  };

  state = {
    projectDescription: marketingUtils.getAutoSavedRequest() || '',
    url: marketingUtils.getAutoSavedUrl() || '',
    submitting: false,
    showUrlInput: true,
  };

  componentDidMount = () => {
    marketingUtils.startupEmbeddedForm(partnerTrackingId);
  };

  onDescChange = e => {
    const projectDescription = e.target.value;
    this.setState({ projectDescription });
    debounce(() => {
      marketingUtils.recordStartEmbeddedForm(partnerTrackingId);
      marketingUtils.autoSaveRequest(projectDescription);
    }, 500)();
  };

  onUrlChange = e => {
    const url = e.target.value;
    this.setState({ url });
    debounce(() => {
      marketingUtils.recordStartEmbeddedForm(partnerTrackingId);
      marketingUtils.autoSaveUrl(url);
    }, 500)();
  };

  setShowUrlInput = showUrlInput => {
    this.setState({ showUrlInput });
    debounce(() => {
      marketingUtils.recordStartEmbeddedForm(partnerTrackingId);
    }, 500)();
  };

  openLink = url => {
    try {
      window.top.location.href = url;
      return;
    } catch (e) {
      console.log('blocked iframe href move so doing a new tab');
      console.error(e);
    }
    window.open(url, '_top');
  };

  goToPartnerPage = () => {
    marketingUtils.recordSubmitEmbeddedForm(partnerTrackingId, false);
    marketingUtils.autoSaveRequest('');
    marketingUtils.autoSaveUrl('');
    this.openLink(
      partnerPage + '?ref=' + partnerTrackingId + '&url=' + this.state.url,
    );
  };

  goToPrefab = prefabId => {
    marketingUtils.recordSubmitEmbeddedForm(partnerTrackingId, true);
    marketingUtils.autoSaveRequest('');
    marketingUtils.autoSaveUrl('');
    let urlToSubmit = this.state.showUrlInput ? this.state.url : 'none';
    try {
      urlToSubmit = encodeURIComponent(urlToSubmit);
    } catch (err) {
      console.log('encodeURIComponent error', err);
    }
    const prefabLink =
      partnerPage +
      '?ref=' +
      partnerTrackingId +
      '&url=' +
      urlToSubmit +
      '&p=' +
      prefabId +
      '&edit=true&pop=true&ref-medium=form';
    this.openLink(prefabLink);
  };

  submitRequest = () => {
    const desc = this.state.projectDescription.trim();
    if (!desc) {
      return this.goToPartnerPage();
    }
    this.setState({ submitting: true });
    this.props
      .mutate({
        variables: {
          title: 'New Project Request (' + partnerName + ')',
          descriptionPlainText: desc,
        },
      })
      .then(({ data, error }) => {
        if (data && data.prefabCreateBasic && data.prefabCreateBasic.id) {
          this.setState({ submitting: false });
          this.goToPrefab(data.prefabCreateBasic.id);
        } else {
          console.log('missing prefabCreateBasic', data, error);
          this.goToPrefab();
        }
      })
      .catch(error => {
        this.setState({ submitting: false });
        console.log('error', error);
        logError(error, {
          component: 'SmartServices',
          func: 'submitRequest',
        });
        this.goToPrefab();
      });
  };

  render() {
    const { showUrlInput, url, projectDescription, submitting } = this.state;
    const paragraphLength = projectDescription.trim().length;
    const paragraphMin = paragraphLength > 0 && paragraphLength < 75;
    return (
      <div className="PXUServices">
        <div className="left-panel">
          <div className="st-logo">
            <img
              src={require('../images/storetasker-logo-white.png')}
              alt="Storetasker logo"
              onClick={() => this.goToPartnerPage()}
            />
          </div>
          <div className="st-desc">
            Storetasker is the <span>fast</span>, <span>affordable</span>, and{' '}
            <span>reliable</span> way to get help improving your Shopify store.
          </div>
          <div className="st-callout">
            <div className="new-project__stars-container">
              <Star className="star" />
              <Star className="star" />
              <Star className="star" />
              <Star className="star" />
              <Star className="star" />
            </div>
            <div className="st-callout-text">
              Trusted by over 30,000 Shopify brands.
            </div>
          </div>
        </div>
        <div className="right-form">
          <div className="new-project__form-container">
            <div className="st-logo-dark">
              <img
                src={require('../images/storetasker-logo-black.png')}
                alt="Storetasker logo"
                onClick={() => this.goToPartnerPage()}
              />
            </div>
            <div className="new-project__how-can-we-help">Start a project</div>
            <div className="new-project__subheader-copy new-project__subheader-copy-small">
              Storetasker is the fast, affordable, and reliable way to get help
              improving your Shopify store.
            </div>
            <div className="new-project__website">
              <div className="new-project__website-description">
                <div className="input-label">Your Website</div>
              </div>
              {showUrlInput && (
                <div className="suggested-url-input">
                  <input
                    value={url}
                    onChange={this.onUrlChange}
                    placeholder="www.example.com"
                    type="text"
                    autoComplete="off"
                    spellCheck="false"
                  />
                </div>
              )}
              {!showUrlInput && (
                <div className="new-project__no-url">
                  <div className="new-project__no-url__wrench" />
                  <div className="new-project__no-url__text">
                    I don't have a website yet.
                  </div>
                  <div
                    className="new-project__no-url__clear_icon"
                    onClick={() => this.setShowUrlInput(true)}
                  />
                </div>
              )}
            </div>
            <div className="project-description">
              <div className="new-project__project-description">
                <div className="input-label">Your Project</div>
                {paragraphMin && (
                  <div className="new-project__min-characters">
                    {paragraphLength} / 75 minimum characters
                  </div>
                )}
              </div>
              <div className="new-project-textarea">
                <textarea
                  value={projectDescription}
                  rows="5"
                  onChange={this.onDescChange}
                  placeholder="Example: I want help customizing my Smart theme and adding in-cart upsells in order to increase conversion rates and average order value."
                />
              </div>
            </div>
          </div>
          <div className="new-project__submit">
            <div className="new-project__submit__btn">
              <div
                onClick={submitting ? undefined : () => this.submitRequest()}
                className="new-project-submit-cta"
              >
                {submitting ? (
                  <img
                    className="spinner"
                    src={require('../images/cta-spinner.png')}
                    alt="spinner"
                  />
                ) : (
                  <Fragment>
                    Get a quote <span>- it's free</span>
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default graphql(CREATE_PREFAB)(SmartServices);

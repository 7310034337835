import { Component } from 'react';
import PropTypes from 'prop-types';
import logError from '../utils/airbrake';

export default class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.node,
  };

  static defaultProps = {
    children: null,
  };

  componentDidCatch = (error, info) => {
    // Send error to Airbrake
    logError(error, { info });
  };

  render() {
    return this.props.children;
  }
}

const pick = (production, staging, dev) => {
  if (process.env.NODE_ENV === 'test') return staging;
  if (process.env.REACT_APP_STAGING) return staging;
  if (process.env.NODE_ENV === 'production') return production;
  if (dev === undefined) return staging;
  return dev;
};

const get = () => {
  if (process.env.NODE_ENV === 'test') return 'test';
  if (process.env.REACT_APP_STAGING) return 'staging';
  if (process.env.NODE_ENV === 'production') return 'production';
  return 'dev';
};

export default {
  pick,
  get
};

/* global gtag */
import axios from 'axios';
import envUtils from './env';
import urlUtils from './url';
import tokenUtils from './token';

const API_HOST = envUtils.pick(
  'https://api.storetasker.com',
  'https://dev.storetasker.com',
  'http://localhost:4000',
);
const postRequest = (endpoint, payload) =>
  axios.post(API_HOST + endpoint, payload);
// const getRequest = endpoint => (axios.get(API_HOST + endpoint));
const sendMarketingEvents = envUtils.get() === 'production';

let leadFound;
let waitingForLead = [];

const embedParentUrl = urlUtils.getQueryParam('site') || '';

const customerLocal = tokenUtils.read('customerId') || '';
const leadLocal = tokenUtils.read('leadId') || '';

const ogMedium = tokenUtils.read('ogMedium');
let newMedium = '';

const waitForLead = fn => {
  waitingForLead.push(fn);
};

const createLoremLead = function(leadId, cb) {
  postRequest('/lead', {
    leadId: leadId,
    createOptions: {
      leadCreator: 'embed',
    },
  })
    .then(res => res.data)
    .then(result => {
      if (!result || !result.lead)
        return cb('createLoremLead No Lead Data Returned');
      cb(null, result.lead);
    })
    .catch(err => {
      console.log('createLoremLead error: ', err);
      cb(err);
    });
};

const startupEmbeddedForm = function(partnerId) {
  if (!ogMedium) {
    newMedium =
      (leadLocal || customerLocal ? '(existing)' : '') ||
      embedParentUrl ||
      '(embed)';
    tokenUtils.write('ogMedium', newMedium, 1000);
  }
  const leadId = tokenUtils.read('leadId') || '';
  createLoremLead(leadId, function(err, leadObj) {
    if (err) return console.log('error creating lead', err);
    tokenUtils.write('leadId', leadObj._id, 1000);
    setUserId(leadObj._id);
    leadFound = leadObj;
    waitingForLead.forEach(fn => fn());
  });
};

let startedEmbeddedForm = false;
const recordStartEmbeddedForm = function(partnerId) {
  if (!startedEmbeddedForm) {
    startedEmbeddedForm = true;
    const leadId = tokenUtils.read('leadId') || '';
    if (!leadId) {
      waitForLead(() => {
        recordStartEmbeddedFormEvent(leadFound._id, partnerId);
      });
    } else {
      recordStartEmbeddedFormEvent(leadId, partnerId);
    }
  }
};

const recordStartEmbeddedFormEvent = function(leadId, partnerId) {
  if (!customerLocal) {
    // send event
    recordMarketingEvent('StartEmbeddedForm', 'Click', null, null);
  }
};

const recordSubmitEmbeddedForm = function(partnerId, isFilledOut) {
  const leadId = tokenUtils.read('leadId') || '';
  if (!leadId) {
    waitForLead(() => {
      recordSubmitEmbeddedFormEvent(leadFound._id, partnerId, isFilledOut);
    });
  } else {
    recordSubmitEmbeddedFormEvent(leadId, partnerId, isFilledOut);
  }
};

const recordSubmitEmbeddedFormEvent = function(leadId, partnerId, isFilledOut) {
  if (!customerLocal) {
    // send event
    recordMarketingEvent('SubmitPartnerForm', 'Click', null, null);
  }
};

const getAutoSavedRequest = function() {
  return tokenUtils.readLocalStorage('lorem-auto-save-request') || '';
};

const autoSaveRequest = function(textToSave) {
  tokenUtils.writeLocalStorage('lorem-auto-save-request', textToSave);
};

const getAutoSavedUrl = function() {
  return tokenUtils.readLocalStorage('lorem-auto-save-url') || '';
};

const autoSaveUrl = function(textToSave) {
  tokenUtils.writeLocalStorage('lorem-auto-save-url', textToSave);
};

const recordMarketingEvent = function(eventName, gaAction, gaValue, gaLabel) {
  if (!sendMarketingEvents) return;
  if (gaAction && gtag) {
    const gaObj = {
      event_category: eventName,
    };
    if (gaValue) gaObj.value = gaValue;
    if (gaLabel) gaObj.event_label = gaLabel;

    gtag('event', gaAction, gaObj);
  }
};

const setUserId = function(leadId) {
  if (!sendMarketingEvents) return;
  if (leadId) {
    const setObj = { userId: leadId, dimension1: leadId };
    if (newMedium) {
      setObj.dimension2 = newMedium;
      setObj.dimension3 = window.location.href;
    }
    gtag('set', setObj);
    recordMarketingEvent('SetUserId', 'Set', null, null);
  }
};

export default {
  startupEmbeddedForm,
  recordStartEmbeddedForm,
  recordSubmitEmbeddedForm,
  getAutoSavedRequest,
  autoSaveRequest,
  getAutoSavedUrl,
  autoSaveUrl,
};

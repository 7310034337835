import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import envUtils from './utils/env';
import ErrorBoundary from './components/ErrorBoundary';
import DefaultServices from './embeds/DefaultServices';
import OOTSServices from './embeds/OOTSServices';
import PXUServices from './embeds/PXUServices';
import ShopCircleServices from './embeds/ShopCircleServices';
import ElevarServices from './embeds/ElevarServices';
import EightServices from './embeds/EightServices';
import KrownServices from './embeds/KrownServices';
import ArchetypeServices from './embeds/ArchetypeServices';
import WebflowServices from './embeds/WebflowServices';
import KnoServices from './embeds/KnoServices';
import ZigpollServices from './embeds/ZigpollServices';
import AsquaredServices from './embeds/AsquaredServices';
import SmartServices from './embeds/SmartServices';
import SwitchServices from './embeds/SwitchServices';
import urlUtils from './utils/url';
import './App.scss';

const embedId = urlUtils.getQueryParam('id') || '';

const currEmbed =
  {
    'archetype-services': ArchetypeServices,
    'asquared-services': AsquaredServices,
    'elevar-services': ElevarServices,
    'eight-services': EightServices,
    'kno-services': KnoServices,
    'krown-services': KrownServices,
    'oots-services': OOTSServices,
    'pxu-services': PXUServices,
    'shopcircle-services': ShopCircleServices,
    'smart-services': SmartServices,
    'switch-services': SwitchServices,
    'webflow-services': WebflowServices,
    'zigpoll-services': ZigpollServices,
  }[embedId] || DefaultServices;

class AppEmbed extends Component {
  componentDidMount = () => {
    console.log('AppEmbed componentDidMount', embedId);
    if (currEmbed) {
      this.sendHeightUpdate();
      window.addEventListener('orientationchange', () =>
        this.sendHeightUpdate(),
      );
      window.addEventListener(
        'resize',
        debounce(() => this.sendHeightUpdate(), 50),
      );
      setInterval(() => this.sendHeightUpdate(), 500);
    }
  };

  sendHeightUpdate = () => {
    const scrollHeight = document.body.scrollHeight;
    window.top.postMessage(
      {
        scrollHeight,
        embedId,
      },
      '*',
    );
  };

  componentWillUnmount = () => {
    console.log('AppEmbed componentWillUnmount', embedId);
  };

  checkRedirect = () => {
    if (envUtils.get() === 'production' && window.top === window) {
      window.location.href = 'https://storetasker.com';
    }
  };

  render() {
    const Embed = currEmbed || null;
    if (!Embed) this.checkRedirect();
    return (
      <ErrorBoundary>
        <div className="App AppEmbed">
          {Embed && (
            <Embed
              didUpdate={this.sendHeightUpdate}
              embedServicesId={embedId}
            />
          )}
        </div>
      </ErrorBoundary>
    );
  }
}

export default AppEmbed;

/* global window */
import envUtils from './env';
const getBrand = () => {
  const sitehost = window.location.host;
  const [sub, domain] = sitehost.split('.');
  if (domain) {
    if (domain === 'storetasker') {
      return 'storetasker';
    } else if (domain === 'asklorem') {
      return 'lorem';
    }
  } else {
    if (sub.startsWith('localhost:3')) {
      return 'lorem';
    } else if (sub.startsWith('localhost:4')) {
      return 'storetasker';
    }
  }
  return 'lorem';
};
const brand = getBrand();
const brandDomain =
  brand === 'storetasker' ? 'storetasker.com' : 'asklorem.com';
const cookieDomain = envUtils.pick(brandDomain, brandDomain, '');

const write = (key, value, days) => {
  writeCookie(key, value, days || 1000);
};

const erase = key => {
  eraseCookie(key);
};

const read = key => readCookie(key);

const readLocalStorage = function(key) {
  let val;
  try {
    val = window.localStorage.getItem(key);
  } catch (e) {
    val = null;
  }
  return val;
};

const writeLocalStorage = function(key, val) {
  let wrote;
  try {
    window.localStorage.setItem(key, val);
    wrote = true;
  } catch (e) {
    wrote = false;
  }
  return wrote;
};

const writeCookie = function(name, value, days) {
  let expires = '';
  let domainStr = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toGMTString();
  }

  if (cookieDomain) domainStr = '; domain=' + cookieDomain;
  window.document.cookie =
    name + '=' + value + expires + domainStr + '; path=/';
};

const readCookie = function(name) {
  const nameEQ = name + '=';
  const ca = window.document.cookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

const eraseCookie = function(name) {
  write(name, '', -1);
};

export default {
  read,
  write,
  erase,
  readLocalStorage,
  writeLocalStorage,
};

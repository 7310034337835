/* global document */

import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import './App.scss';
import AppEmbed from './AppEmbed';
import apolloUtils from './utils/apollo';
import logError from './utils/airbrake';

function getSubdomain() {
  const sitehost = window.location.host;
  const sub = sitehost.split('.')[0];
  if (sub === 'localhost:3004' || sub === '192')
    return fakeSubdomain() || 'embed';
  return sub;
}

function fakeSubdomain() {
  return 'embed';
}

window.addEventListener('unhandledrejection', function(err) {
  // Catch all handler to notify HQ in the event a promise was rejected, but never handled
  // This event is not implemented in Firefox yet, just Chrome: https://developer.mozilla.org/en-US/docs/Web/API/PromiseRejectionEvent
  let message;
  try {
    message = JSON.stringify(err.reason);
  } catch (e) {
    message = err.reason;
  }
  logError(`Uncaught promise error (missing a catch() somewhere): ${message}`);

  // To actually send the error you can do this, i'm not sure what is more useful - knowing that the actual error
  // or just knowing that a promise was rejected and not handled (ie. a catch block needs to be added to handle it somewhere)
  if (err.promise) {
    err.promise.catch(e => {
      console.error('err.promise.catch', e);
      logError(e);
    });
  }
});

const subdomain = getSubdomain();
let apolloClient;
switch (subdomain) {
  case 'embed':
  case 'embed-dev':
    document.title = 'Storetasker';
    apolloClient = apolloUtils.create();
    ReactDOM.render(
      <ApolloProvider client={apolloClient}>
        <AppEmbed />
      </ApolloProvider>,
      document.getElementById('root'),
    );
    break;
  default:
    ReactDOM.render(
      <div>
        Invalid Domain. Please go to{' '}
        <a href="https://www.storetasker.com">Storetasker.com</a> to start again
        or email{' '}
        <a href="mailto:support@storetasker.com">support@storetasker.com</a>.
      </div>,
      document.getElementById('root'),
    );
    logError(`Invalid subdomain ${subdomain}`);
    break;
}
